











import Vue from 'vue';
import { routerNames } from '@/types/routerNames';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    back() {
      this.$router.push({
        name: routerNames.schedule,
      });
    },
  },
});
