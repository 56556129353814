



























import Vue from 'vue';
import Header from '@/components/employee/Header.vue';

export default Vue.extend({
  components: {
    Header,
  },
  data() {
    return {
      // Note: ヘルプを追加したらアイテム数を変更
      numberOfitems: [2, 7, 4],
    };
  },
});
